import Vue from "vue"
 
var d3 = require("d3")

import * as summaryData from "./data/summary.json";
import * as clientTypeData from "./data/clienttype.json";
import * as clientOriginationData from "./data/clientorigination.json";

const GraphType = {
  NONE: 0,
  CLIENTTYPE: 1,
  CLIENTORIGINATION: 2,
};

const chartWidth = 600;
const chartHeight = 600;
const chartRadius = 200;

var labelPosition = {x: 0, y: 0};
var labelText = "";

var canShowGraph = true;

export default Vue.component('index', {
  template: () => import('./index.vue'),
  data: function() {
    return {
      summaryData: summaryData.default[0],

      enumClientType: GraphType.CLIENTTYPE,
      enumOriginationType: GraphType.CLIENTORIGINATION,

      canShowGraph: canShowGraph
    };
  },
  methods: {
    //-- Generates an array of randomized HEX colours --//
    generateColorArray: function(numColors)
    {
      let colorArray = [];

      for(let i = 0; i < numColors; i++)
      {
        //-- 16777215 represents White - RGB(255,255,255). This finds a colour integer between full black and full white then converts to HEX --//
        colorArray.push('#' + Math.floor(Math.random() * 16777215).toString(16));
      }
      
      return colorArray;
    },

    //-- Generates a NEW graph each time a graph button is clicked. --//
    generateGraph: function(type) 
    {
      let svg, chartTranslation;
      let data, pie, ordinalScale, arc;

      switch(type)
      {
        case GraphType.NONE:
          return;
        case GraphType.CLIENTTYPE:
          data = clientTypeData.default;
          break;
        case GraphType.CLIENTORIGINATION:
          data = clientOriginationData.default;
          break;
        default: break;
      }

      svg = d3.select(".page-content")
        .append("svg")
        .attr("width", chartWidth)
        .attr("height", chartHeight)
        .attr("radius", chartRadius);

      chartTranslation = d3.select("svg")
      .append("g")
        .attr("transform", "translate(" + chartWidth / 2 + "," + chartWidth / 2 + ")");

      ordinalScale = d3.scaleOrdinal()
      .domain(data)
      .range(this.generateColorArray(data.length));

      pie = d3.pie().value(function(d) { 
        return d.Percentage; 
      });

      arc = chartTranslation.selectAll("arc")
        .data(pie(data))
        .enter();

      var path = d3.arc()
        .outerRadius(chartRadius)
        .innerRadius(0);

      arc.append("path")
        .attr("d", path)
        .attr("fill", function(d) { 

          switch(type)
          {
            case GraphType.CLIENTTYPE:
              return ordinalScale(d.data.ClientType);
            case GraphType.CLIENTORIGINATION:
              return ordinalScale(d.data.ClientOrigination)
          }
        })
        //-- Generate a new label for the pie slice that's being hovered and translate it to the pie slice edge --//
        .on('mouseenter', function(e, d)
        {
          var label = d3.arc()
            .outerRadius(chartRadius * 2.25)
            .innerRadius(0);

            labelPosition.x = label.centroid(d)[0];
            labelPosition.y = label.centroid(d)[1];
            
            d3.select(this.parentNode)
            .append("text")
            .attr("transform","translate(" + labelPosition.x + "," + labelPosition.y + ")")
            .text(d.data.Count)
        })
        //-- Remove the label --//
        .on('mouseleave', function(e, d)
        {
          d3.select(this.parentNode)
            d3.selectAll("text")
              .remove()
        });
    },
    displayGraph: function(type)
    {
      this.canShowGraph = true;

      switch(type)
      {
        case GraphType.CLIENTTYPE:
          this.clearGraph();
          this.generateGraph(GraphType.CLIENTTYPE);
          break;
        case GraphType.CLIENTORIGINATION:
          this.clearGraph();
          this.generateGraph(GraphType.CLIENTORIGINATION);
          break;
      }
    },
    displayUnavailableDataWarning: function() {
      this.canShowGraph = false;

      this.clearGraph();
    },
    clearGraph: function() {
      d3.selectAll("svg")
      .remove();
    }
  },
  mounted() {
    this.generateGraph(GraphType.CLIENTTYPE);
  }
})